<template>
  <div id="domain-transfer-status">
    <div v-if="hasUnApprovedTransferFoa">
      <h4 class="font-weight-bold">Transfer initiated for {{ activeDomainTransferRequest.domain_name }}</h4>
      <p class="pt-4">
        The request to transfer <strong>{{ activeDomainTransferRequest.domain_name }}</strong> has been
        initiated.
      </p>
      <p> We have sent you an email containing a confirmation of transfer authorization which must be accepted within 24 hours. </p>
      <p> Please check your email and respond to the request by clicking the included link in the authorization form. </p>
      <p> If you have not accepted the authorization within the the 24 hour time period, the transfer request will expire.</p>
      <div class="pt-4">Email sent on {{ getDateTime(activeDomainTransferRequest.foa_notification_sent) }}</div>
    </div>
    <b-row v-else>
      <b-col cols="4" class="checkbox-section">
        <ul class="progress">
          <li class="progress-step">
            <div
              class="details completed"
            >
              <p class="date">
                {{ getDate(activeDomainTransferRequest.created_at) }}
              </p>
              <h3 class="title">
                Transfer Initiated
              </h3>
            </div>
          </li>
          <li class="progress-step">
            <div
              class="details completed"
            >
              <p class="date">
                {{ getDate(activeDomainTransferRequest.foa_accepted) }}
              </p>
              <h3 class="title">
                Pending Transfer
              </h3>
            </div>
          </li>
          <li v-if="!transferError" class="progress-step">
            <div
              class="details"
              :class="progressClass"
            >
              <p v-if="status === 'approved'" class="date">
                {{ getDate(activeDomainTransferRequest.updated_at) }}
              </p>
              <h3 class="title " :class="status === 'pending' && 'no-date'">
                Transfer Approved
              </h3>
            </div>
          </li>
          <li v-else class="progress-step">
            <div
              class="details"
              :class="'error'"
            >
              <p class="date">
                {{ getDate(activeDomainTransferRequest.updated_at) }}
              </p>
              <h3 class="title">
                Transfer {{ statusCapitalized }}
              </h3>
            </div>
          </li>
        </ul>
      </b-col>
      <b-col class="info-section">
        <h4 class="font-weight-bold">Transfer {{ status }} for {{ activeDomainTransferRequest.domain_name }}</h4>
        <p v-if="status === 'pending'" class="pt-2">
          Your request to transfer <strong>{{ activeDomainTransferRequest.domain_name }}</strong> has been submitted and is pending approval.
          Transfer requests can take a few days to process and you will receive a notification when the process is complete.
          You may check back here at any time to view the current status.
        </p>
        <p v-else-if="transferError === 'rejected'" class="pt-2">
          Your transfer request has been rejected.
        </p>
        <p v-else class="pt-2">
          We're thrilled to inform you that the transfer of <strong>{{ activeDomainTransferRequest.domain_name }}</strong> has been
          successfully approved and completed. The domain is now officially under our
          registrar's care, and we're excited to have you with us.
        </p>
        <primary-button
          v-if="status === 'approved'"
          class="manage-domain-button"
          button-text="Manage My Domain"
          aria-label="manage domain button"
          @onclick="goToWebsites"
        />
        <primary-button
          v-if="transferError === 'rejected'"
          class="manage-domain-button"
          button-text="Return to Domain Selection"
          aria-label="return to domain selection button"
          :disabled="loading"
          :loading="loading"
          @onclick="cancelRequest"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { formatDateTimeLong, formatFullDate } from '@/common/modules/formatters'

export default {
  name: 'DomainTransferStatus',
  components: {
    PrimaryButton:     () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  mixins: [
    makeToastMixin,
  ],
  data() {
    return {
      pollInterval: null,
      pollCount: 1,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'activeDomainTransferRequest',
    ]),
    status() {
      if(this.activeDomainTransferRequest.status.toLowerCase().includes("pending")) return "pending"
      if(this.activeDomainTransferRequest.status.toLowerCase().includes("cancel")) return "cancelled"
      if(this.activeDomainTransferRequest.status.toLowerCase().includes("reject")) return "rejected"
      return "approved"
    },
    hasUnApprovedTransferFoa() {
      return !this.activeDomainTransferRequest.foa_accepted
    },
    statusCapitalized() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1)
    },
    progressClass() {
      return this.status === "pending" ? "incomplete" : "completed"
    },
    companyId() {
      return this.$route.params.companyId
    },
    transferError() {
      return ["cancelled", "rejected"].includes(this.status) ? this.status : null
    },
  },
  mounted() {
    if (this.status === "pending") {
      this.pollInterval =  setInterval(this.checkTransferStatus, 300000) // 5 minute interval
      setTimeout(() => { clearInterval(this.pollInterval) }, 36000000) // stop after 1 hour
    }
  },
  beforeDestroy() {
    clearInterval(this.pollInterval)
  },
  methods: {
    ...mapActions('domains', [
      'fetchDomainTransferRequest',
      'cancelDomainTransferRequest',
    ]),
    getDate(date) {
      return formatFullDate(date)
    },
    getDateTime(date){
      return formatDateTimeLong(date)
    },
    async goToWebsites() {
      await this.$router.push("/websites")
    },
    async checkTransferStatus() {
      await this.fetchDomainTransferRequest(this.activeDomainTransferRequest.id)
      if (this.status !== "pending") clearInterval(this.pollInterval)
    },
    async cancelRequest(){
      this.loading = true
      try {
        await this.cancelDomainTransferRequest(this.activeDomainTransferRequest.id)
      } catch {
        this.errorToast("Submission Error", "There was an error submitting your request.")
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">


#domain-transfer-status{
  $violet2: #000864;
  $circle-size-small: 16px;
  $circle-size-large: 30px;
  $circle-position-top: 35px;
  $circle-position-left: -15px;
  $icon-check: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>';
  $icon-cross: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polyline points="0,0 25,25"/> <polyline points="25,0 0,25"/></svg>';

  @mixin typography($font-size: 1.0em, $font-weight: 400, $color: $violet2) {
    font-family: "Open Sans", "Roboto", sans-serif;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $violet2;
  }

  @mixin circle(
    $background: white,
    $top: $circle-position-top,
    $left: $circle-position-left,
    $circle-size: $circle-size-large
  ) {
    content: '';
    position: absolute;
    width: $circle-size;
    height: $circle-size;
    top: $top;
    left: $left;
    background-color: $background;
    border-radius: 50%;
  }

  * {
    padding: 0;
    margin: 0;
  }

  .info-section{
    margin-top: 1.25em;

    .manage-domain-button {
      width: 250px;
      height: 45px;
      margin-top: 3.75em;
    }
  }

  .checkbox-section {
    width: 100%;
    align-items: center;

    p {
      @include typography();
    }

    h3 {
      @include typography(
        $font-size: 1.5em,
        $font-weight: $ct-ui-font-weight-7
      );
    }

    .transfer-link {
      color: #2FB2AE;
    }

    ul.progress {
      width: 100%;
      height: 100%;
      list-style: none;
      background: inherit;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;

      li.progress-step {
        margin: 0 2.0em;
        padding: 0.625em;
        position: relative;
        height: 140px;
        box-sizing: border-box;

        &:before {
          content: '';
          position: absolute;
          width: 0.125em;
          height: 100%;
          top: calc(#{$circle-position-top + 25px});
          left: calc(#{$circle-position-left + 14px});
          background: $violet2;
        }

        &:after {
          @include circle($background: white);
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;

          &:before {
            width: 0;
          }
        }

        div.details {
          padding: 1.0em 2.0em;
          position: relative;

          &.incomplete {
            position: relative;

            h3 {
              color: gray;
            }

            &:before {
              @include circle(
                $background: gray,
                $top: calc(#{$circle-position-top} - 8px),
                $left: calc(#{$circle-position-left} - 8px)
              );
              z-index: 1000;
            }

            &:after {
              content: url($icon-check);
              position: absolute;
              top: calc(#{$circle-position-top} - 1px);
              left: calc(#{$circle-position-left} + 1px);
              z-index: 1100;
            }
          }

          &.completed {
            position: relative;

            &:before {
              @include circle(
                $background: $violet2,
                $top: calc(#{$circle-position-top} - 8px),
                $left: calc(#{$circle-position-left} - 8px)
              );
              z-index: 1000;
            }

            &:after {
              content: url($icon-check);
              position: absolute;
              top: calc(#{$circle-position-top} - 1px);
              left: calc(#{$circle-position-left} + 1px);
              z-index: 1100;
            }
          }

          &.error {
            position: relative;

            &:before {
              @include circle(
                $background: red,
                $top: calc(#{$circle-position-top} - 8px),
                $left: calc(#{$circle-position-left} - 8px)
              );
              z-index: 1000;
            }

            &:after {
              content: url($icon-cross);
              position: absolute;
              top: calc(#{$circle-position-top} - 1px);
              left: calc(#{$circle-position-left} + 1px);
              z-index: 1100;
            }
          }

          .no-date {
            padding-top: 1.0em;
          }
        }
      }
    }
  }
}
</style>
