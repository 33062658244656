var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-transfer-status" } },
    [
      _vm.hasUnApprovedTransferFoa
        ? _c("div", [
            _c("h4", { staticClass: "font-weight-bold" }, [
              _vm._v(
                "Transfer initiated for " +
                  _vm._s(_vm.activeDomainTransferRequest.domain_name)
              ),
            ]),
            _c("p", { staticClass: "pt-4" }, [
              _vm._v("\n      The request to transfer "),
              _c("strong", [
                _vm._v(_vm._s(_vm.activeDomainTransferRequest.domain_name)),
              ]),
              _vm._v(" has been\n      initiated.\n    "),
            ]),
            _c("p", [
              _vm._v(
                " We have sent you an email containing a confirmation of transfer authorization which must be accepted within 24 hours. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Please check your email and respond to the request by clicking the included link in the authorization form. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " If you have not accepted the authorization within the the 24 hour time period, the transfer request will expire."
              ),
            ]),
            _c("div", { staticClass: "pt-4" }, [
              _vm._v(
                "Email sent on " +
                  _vm._s(
                    _vm.getDateTime(
                      _vm.activeDomainTransferRequest.foa_notification_sent
                    )
                  )
              ),
            ]),
          ])
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "checkbox-section", attrs: { cols: "4" } },
                [
                  _c("ul", { staticClass: "progress" }, [
                    _c("li", { staticClass: "progress-step" }, [
                      _c("div", { staticClass: "details completed" }, [
                        _c("p", { staticClass: "date" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getDate(
                                  _vm.activeDomainTransferRequest.created_at
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                        _c("h3", { staticClass: "title" }, [
                          _vm._v(
                            "\n              Transfer Initiated\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", { staticClass: "progress-step" }, [
                      _c("div", { staticClass: "details completed" }, [
                        _c("p", { staticClass: "date" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getDate(
                                  _vm.activeDomainTransferRequest.foa_accepted
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                        _c("h3", { staticClass: "title" }, [
                          _vm._v(
                            "\n              Pending Transfer\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.transferError
                      ? _c("li", { staticClass: "progress-step" }, [
                          _c(
                            "div",
                            {
                              staticClass: "details",
                              class: _vm.progressClass,
                            },
                            [
                              _vm.status === "approved"
                                ? _c("p", { staticClass: "date" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.getDate(
                                            _vm.activeDomainTransferRequest
                                              .updated_at
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "h3",
                                {
                                  staticClass: "title",
                                  class: _vm.status === "pending" && "no-date",
                                },
                                [
                                  _vm._v(
                                    "\n              Transfer Approved\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _c("li", { staticClass: "progress-step" }, [
                          _c(
                            "div",
                            { staticClass: "details", class: "error" },
                            [
                              _c("p", { staticClass: "date" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getDate(
                                        _vm.activeDomainTransferRequest
                                          .updated_at
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _c("h3", { staticClass: "title" }, [
                                _vm._v(
                                  "\n              Transfer " +
                                    _vm._s(_vm.statusCapitalized) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "info-section" },
                [
                  _c("h4", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "Transfer " +
                        _vm._s(_vm.status) +
                        " for " +
                        _vm._s(_vm.activeDomainTransferRequest.domain_name)
                    ),
                  ]),
                  _vm.status === "pending"
                    ? _c("p", { staticClass: "pt-2" }, [
                        _vm._v("\n        Your request to transfer "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.activeDomainTransferRequest.domain_name)
                          ),
                        ]),
                        _vm._v(
                          " has been submitted and is pending approval.\n        Transfer requests can take a few days to process and you will receive a notification when the process is complete.\n        You may check back here at any time to view the current status.\n      "
                        ),
                      ])
                    : _vm.transferError === "rejected"
                    ? _c("p", { staticClass: "pt-2" }, [
                        _vm._v(
                          "\n        Your transfer request has been rejected.\n      "
                        ),
                      ])
                    : _c("p", { staticClass: "pt-2" }, [
                        _vm._v(
                          "\n        We're thrilled to inform you that the transfer of "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.activeDomainTransferRequest.domain_name)
                          ),
                        ]),
                        _vm._v(
                          " has been\n        successfully approved and completed. The domain is now officially under our\n        registrar's care, and we're excited to have you with us.\n      "
                        ),
                      ]),
                  _vm.status === "approved"
                    ? _c("primary-button", {
                        staticClass: "manage-domain-button",
                        attrs: {
                          "button-text": "Manage My Domain",
                          "aria-label": "manage domain button",
                        },
                        on: { onclick: _vm.goToWebsites },
                      })
                    : _vm._e(),
                  _vm.transferError === "rejected"
                    ? _c("primary-button", {
                        staticClass: "manage-domain-button",
                        attrs: {
                          "button-text": "Return to Domain Selection",
                          "aria-label": "return to domain selection button",
                          disabled: _vm.loading,
                          loading: _vm.loading,
                        },
                        on: { onclick: _vm.cancelRequest },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }