import { render, staticRenderFns } from "./DomainTransferStatus.vue?vue&type=template&id=1fa8e6d2&scoped=true"
import script from "./DomainTransferStatus.vue?vue&type=script&lang=js"
export * from "./DomainTransferStatus.vue?vue&type=script&lang=js"
import style0 from "./DomainTransferStatus.vue?vue&type=style&index=0&id=1fa8e6d2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa8e6d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fa8e6d2')) {
      api.createRecord('1fa8e6d2', component.options)
    } else {
      api.reload('1fa8e6d2', component.options)
    }
    module.hot.accept("./DomainTransferStatus.vue?vue&type=template&id=1fa8e6d2&scoped=true", function () {
      api.rerender('1fa8e6d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Services/Domains/DomainTransferStatus.vue"
export default component.exports